/* You can add global styles to this file, and also import other style files */
html,
body {
  font-family: 'Poppins', sans-serif;;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Poppins', "Helvetica Neue", sans-serif;
}
.font-size-10 {
  font-size: 10px;;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-25 {
  font-size: 25px;
}
.font-size-6-vh {
  font-size: 6vh;
}
.font-size-3-vh {
  font-size: 3vh;
}
.font-size-3_8-vh {
  font-size: 3.8vh;
}
.font-size-4-vh {
  font-size: 4vh;
}
.padding-left-0_8 {
  padding-left: 0.8rem;
}
.multiple-vehicle .gm-style div[aria-hidden="true"] {
  background-color: #324151 !important;
  padding: 5px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 10px;
  position: absolute;
  top: 15px;
  right: 4px;
  letter-spacing: 1px;
}
.single-vehicle .gm-style div{
   color: #fff !important; 
}
.single-vehicle .gm-style-iw.gm-style-iw-c {
  color :black !important
}

.pdf-container {
  font-size: 10px;
}
.ngx-pagination {
  margin: 0 !important;
}

.ngx-pagination .current,
.btn-info {
  background: #e6e6e6 !important;
  border: 1px solid #24282E !important;
  border-radius: 2px !important;
}

.ngx-pagination .current:hover {
  background: #24282E !important;
  border-radius: 2px !important;
  border: 1px solid #24282E !important;
}

.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  border: 1px solid #24282E !important;
  border-radius: 3px;
  color: #24282E !important;
}

.ngx-pagination li {
  margin-right: 0.0625rem;
  border: 1px solid #24282E !important;
  border-radius: 3px;
}

.ngx-pagination a {
  cursor: pointer;
  text-decoration: none !important;
}
.pagination-flex_row_center_spacebetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.device-list-page ul.ngx-pagination {
  font-size:10px;
  padding-left: 0px !important;
}
/* .device-list-page ul.ngx-pagination .pagination-next{
  padding: 0.1875rem 5px;
} */
.modal-body.modal-bodys accordion{
  /* overflow-x: hidden;
  overflow-y: scroll;
  height: 75vh; */
}
.form-control-mt-select .mat-select-value{
  padding-top: 5px;
    padding-left: 2px;
}
.search-dealer .mat-form-field-appearance-fill .mat-form-field-flex{
  padding: 0 3px !important;
}
.running-bg-color {
  background-color: #2e972e;
}
.stopped-bg-color {
  background-color: #ff4545;
}
.idle-bg-color {
  background-color: #dbb239;
}
.not-reporting-bg-color {
  background-color: #3535ff;
}
.running-text-color {
  color: #2e972e;
}
.stopped-text-color {
  color: #ff4545;
}
.idle-text-color {
  color: #dbb239;
}
.not-reporting-text-color {
  color: #3535ff;
}
.modal-body.modal-bodys accordion .panel-heading.card-header {
  background: rgb(248,249,250);
}
.modal-body.modal-bodys accordion .panel-title button.btn.btn-link {
  background: rgb(248,249,250);
  text-decoration: none;
  color: #000;
  padding-left: 0px;
}
.btn-outline-dark:hover {
  color: #000;
  background-color: inherit;
}
